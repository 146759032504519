import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取车辆段信息
 * @param {Object} data
 * @returns
 */
export function getList (data) {
  return request.post('/ooh-msp/v1/trainpark/getList', qs.stringify(data))
}
