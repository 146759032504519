<template>
  <div>
    <Modal v-model="parkPlanModal" width="800" footer-hide>
      <p slot="header" class="text-center">{{ optionType === 1 ? '新增车场计划' : '编辑车场计划' }}</p>
      <Form ref="modifyParkPlan" :model="formData" :rules="ruleValidate" label-position="top">
        <FormItem label="项目名称" prop="name">
          <Input size="small" v-model="formData.name" placeholder="填写项目名称" />
        </FormItem>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="选择作业日期" prop="actionDate">
              <DatePicker type="date" size="small" v-model="formData.actionDate" placeholder="选择作业日期"
                style="width: 100%">
              </DatePicker>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="选择施工区段" prop="trainId">
              <Select v-model="formData.trainId" size="small" placeholder="选择施工区段" @on-change="onChangeTrainId">
                <Option v-for="item in areaList" :value="item.id" :key="item.id">{{ item.trainParkName }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="运营段公司（根据施工区段自动匹配）">
              <Input size="small" readonly v-model="operatCom" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="施工人数">
              <InputNumber size="small" style="width: 100%;" :max="99" :min="0" v-model="formData.workNumber">
              </InputNumber>
            </FormItem>
          </i-col>
        </Row>
        <FormItem label="施工内容">
          <Input size="small" type="textarea" :rows="2" v-model="formData.workContent" placeholder="请输入施工内容" />
        </FormItem>
        <FormItem label="施工车号">
          <Input size="small" type="textarea" :rows="2" v-model="formData.trainCodes" placeholder="请输入施工车号" />
        </FormItem>
        <FormItem label="需要施工用车进段车号">
          <Input size="small" type="textarea" :rows="2" v-model="formData.carInfos" placeholder="请输入施工用车进段车号" />
        </FormItem>
        <FormItem label="备注">
          <Input size="small" type="textarea" :rows="2" v-model="formData.remark" placeholder="请输入备注" />
        </FormItem>
        <template v-if="isManage">
          <FormItem label="配合单位">
            <Input size="small" v-model="formData.unitCooperation" placeholder="请输入配合单位" />
          </FormItem>
          <Row :gutter="8" v-for="item in contactUserInfos" :key="item.name">
            <i-col span="12">
              <FormItem label="施工配合人/联系人名称">
                <Input size="small" v-model="item.name" placeholder="请输入联系人名称" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="联系电话">
                <Input size="small" v-model="item.phone" placeholder="请输入联系人电话" />
              </FormItem>
            </i-col>
          </Row>
        </template>
        <FormItem class="text-right m-t-20">
          <Button type="text" class="m-r-5" @click="parkPlanModal = false">取消</Button>
          <Button type="primary" :loading="submitLoading" @click="onSubmit">
            {{ optionType === 1 ? '确认创建' : '确认编辑' }}</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { getList } from '@/api/msp/trainpark'
import { createTrainWorkday } from '@/api/msp/workdaySchedule'
import { managerCreateTrainWorkday } from '@/api/msp/workdayManage'
export default {
  props: {
    tableId: {
      type: Number,
      required: true
    },
    optionType: {
      type: Number,
      default: 1 // 1：新增，2：编辑
    },
    isManage: {
      type: Boolean,
      default: false
    },
    workdayId: {
      type: Number
    },
    onSuccess: {
      type: Function,
      default: () => { },
      required: false
    }
  },
  data () {
    return {
      parkPlanModal: false,
      submitLoading: false,
      areaList: [],
      operatCom: '',
      formData: {
        name: '',
        actionDate: '',
        assetId: null,
        tableId: null,
        manageId: null,
        trainId: null,
        trainName: '',
        workNumber: 1,
        carInfos: '',
        contactUserInfo: '',
        remark: '',
        trainCodes: '',
        unitCooperation: '',
        workContent: '',
        workdayId: null
      },
      contactUserInfos: [],
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        actionDate: [
          { required: true, type: 'date', message: ' ', trigger: 'change' }
        ],
        trainId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    showModal () {
      if (this.optionType === 1) {
        Object.assign(this.$data.formData, this.$options.data().formData)
      }
      if (this.isManage) {
        this.formData.manageId = this.tableId
      } else {
        this.formData.tableId = this.tableId
      }

      this.getAreaData()
      this.parkPlanModal = true
    },
    async getAreaData () {
      const reuslt = await getList()
      if (reuslt && !reuslt.errcode) {
        this.areaList = reuslt
      }
    },
    onChangeTrainId () {
      const result = this.areaList.find(x => x.id === this.formData.trainId)
      if (result) {
        this.operatCom = result.yunFen
        this.formData.trainName = result.trainParkName
        this.formData.unitCooperation = result.unitCooperation
        this.contactUserInfos = result.unitUserInfo !== '' ? JSON.parse(result.unitUserInfo) : [{ name: '', phone: '' }]
      }
    },
    onSubmit () {
      console.log('submit')
      debugger
      this.$refs.modifyParkPlan.validate(async (valid) => {
        if (valid) {
          this.formData.actionDate = ParseDate(this.formData.actionDate)
          this.formData.contactUserInfo = JSON.stringify(this.contactUserInfos)
          this.submitLoading = true
          let result = null
          if (this.isManage) {
            // 管理端
            result = await managerCreateTrainWorkday(this.formData).finally(() => { this.submitLoading = false })
          } else {
            // 运营公司
            result = await createTrainWorkday(this.formData).finally(() => { this.submitLoading = false })
          }
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.parkPlanModal = false
            this.onSuccess()
          }
          // this.$Modal.confirm({
          //   title: '操作提示',
          //   content: '确认信息无误，并提交？',
          //   onOk: async () => {
          //     this.submitLoading = true
          //     let result = null
          //     if (this.isManage) {
          //       // 管理端
          //       result = await managerCreateTrainWorkday(this.formData).finally(() => { this.submitLoading = false })
          //     } else {
          //       // 运营公司
          //       result = await createTrainWorkday(this.formData).finally(() => { this.submitLoading = false })
          //     }
          //     if (result && result.success) {
          //       this.$Notice.success({ desc: '操作成功' })
          //       this.parkPlanModal = false
          //       this.onSuccess()
          //     }
          //   }
          // })
        }
      })
    }
  }
}
</script>
