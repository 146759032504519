<template>
  <div>
    <Modal v-model="detailModal" width="80%">
      <p slot="header" class="text-center">施工计划详情</p>
      <div class="m-b-10">
        <h4 class="workplatform-title m-t-10 m-b-10">基础信息</h4>
        <Row :gutter="8" class="m-b-10">
          <i-col span="6">
            <span class="title">作业日期</span><span class="p-r-5">{{ info.actionDate }}</span><span>({{ info.dayOfTheWeek
              }})</span>
          </i-col>
          <template v-if="[1, 3].includes(planType)">
            <i-col span="6">
              <span class="title">线路</span><span>{{ info.assetName || '/' }}</span>
            </i-col>
            <i-col span="6">
              <span class="title">站点</span><span>{{ info.stationName || '/' }}</span>
            </i-col>
            <i-col span="6">
              <span class="title">位置</span><span>{{ position || '/' }}</span>
            </i-col>
          </template>
          <template v-else>
            <i-col span="12">
              <span class="title">车场</span><span>{{ info.stationName || '/' }}</span>
            </i-col>
          </template>
        </Row>
        <Row :gutter="8" class="m-b-10">
          <i-col span="6">
            <span class="title">作业时间段</span><span>{{ info.workTime }}</span>
          </i-col>
          <i-col span="6">
            <span class="title">任务类型</span><span>{{ info.taskTypes || '/' }}</span>
          </i-col>
          <i-col span="6">
            <span class="title">任务数量</span><span>{{ info.taskCount }}</span>
          </i-col>
          <i-col span="6">
            <span class="title">施工人数</span><span>{{ info.workerNumber }}</span>
          </i-col>
        </Row>
        <Row :gutter="8" class="m-b-10">

          <i-col span="6">
            <span class="title">施工单位</span>
            <span>{{ info.supplierCompanyNameList && info.supplierCompanyNameList.length
              ? info.supplierCompanyNameList.toString() : '/' }}</span>
          </i-col>
          <i-col span="6">
            <span class="title">任务发起方</span><span>{{ info.companyName || info.createCompanyName }}</span>
          </i-col>
          <i-col span="6">
            <span class="title">备注</span><span>{{ info.remark || '/' }}</span>
          </i-col>
          <i-col span="6">
            <span class="title">状态</span><span>{{ info.statusName }}</span>
          </i-col>
        </Row>
        <Row :gutter="8" class="m-b-10">
          <i-col span="24">
            <span class="title">工作内容</span><span>{{ info.workContent }}</span>
          </i-col>
        </Row>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">任务明细</h4>
        <Row :gutter="8" class="m-b-10">
          <i-col span="6">
            <!-- <Button type="primary" icon="ios-search" size="small" class="m-r-5" @click="onSearch">搜索</Button> -->
            <Button type="success" size="small" class="m-r-5" :disabled="selectedIds.length === 0"
              @click="onModifyActionDate">调整作业日期</Button>
            <Button type="error" size="small" class="m-r-5" :disabled="selectedIds.length === 0"
              @click="onRmove">移除</Button>
          </i-col>
        </Row>
        <div>
          <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="tableLoading"
            @on-selection-change="selectionChange">
          </Table>
          <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator
              :current="query.pageNumber" @on-change="onChangePageNum"></Page>
          </div>
        </div>
      </div>
    </Modal>
    <view-task-file ref="viewTaskFile" />
  </div>
</template>

<script>
import { getWorkdayTaskPage, removeTaskitem, modifyTaskitemActionDate } from '@/api/msp/workdaySchedule'

import ViewTaskFile from './ViewTaskFile.vue'
export default {
  props: {
    planType: {
      type: Number,
      default: 1 // 计划类型，1：车站计划，2：车场计划，3：临时计划
    },
    onSuccess: {
      type: Function,
      default: () => { },
      required: false
    }
  },
  components: { ViewTaskFile },
  data () {
    return {
      detailModal: false,
      info: {},
      query: {
        pageNumber: 1,
        pageSize: 15,
        workdayId: null
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '作业日期', align: 'center', minWidth: 120, key: 'actionDate' },
        { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypeName' },
        { title: '编号', align: 'center', minWidth: 120, key: 'code' },
        { title: '资源类型', align: 'center', minWidth: 120, key: 'deviceModelName' },
        {
          title: '位置',
          align: 'center',
          minWidth: 120,
          key: 'potionName',
          render: (h, { row }) => {
            const html = []
            row.positionList.forEach(item => {
              html.push(h('p', `${item.floor}层 - ${item.positionName}`))
            })
            return h('div', html)
          }
        },
        // { title: '单品', align: 'center', minWidth: 120, key: 'code' },
        {
          title: '作业画面',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            if (row.taskitemFileList && row.taskitemFileList.length > 0) {
              if (row.taskitemFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      // const postData = {
                      //   startIndex: 0,
                      //   imgList: row.taskitemFileList
                      // }
                      // this.fileName = `${row.stationName} ${row.code}`
                      // this.$refs.imgLightBox.init(postData)
                      // this.visiblePreview = true
                      this.$nextTick(() => {
                        this.$refs.viewTaskFile.showModal(row)
                      })
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: row.taskitemFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          this.$nextTick(() => {
                            this.$refs.viewTaskFile.showModal(row)
                          })
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        { title: '作业名称', align: 'center', minWidth: 120, key: 'taskName' },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'createCompanyName' },
        { title: '施工单位', align: 'center', minWidth: 120, key: 'supplierCompanyName' }
        // { title: '用途', align: 'center', minWidth: 120, key: 'code' },

      ],
      visiblePreview: false,
      fileName: '',
      selectedIds: [],
      newActionDate: ''
    }
  },
  computed: {
    position () {
      if (this.info.positionList && this.info.positionList.length) {
        const names = []
        this.info.positionList.forEach(item => {
          names.push(`${item.floor}层 - ${item.positionName}`)
        })
        return names.toString()
      } else {
        return ''
      }
    }
  },
  methods: {
    showModal (data) {
      this.info = data
      this.newActionDate = ''

      this.query.workdayId = this.info.id
      this.getTableData()
      this.detailModal = true
    },

    async getTableData () {
      this.tableLoading = true
      const reuslt = await getWorkdayTaskPage(this.query).finally(() => { this.tableLoading = false })
      if (reuslt && !reuslt.errcode) {
        this.tableList = reuslt.list
        this.total = reuslt.totalRow
      }
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedIds = selections.map(x => x.taskitemId)
    },
    onModifyActionDate () {
      this.$Modal.confirm({
        render: (h) => {
          return h('DatePicker', {
            props: {
              value: this.newActionDate,
              type: 'date',
              size: 'small',
              placeholder: '请选择新的作业日期'
            },
            style: { width: '100%' },
            on: {
              'on-change': (val) => {
                this.newActionDate = val
              }
            }
          })
        },
        onOk: () => {
          if (!this.newActionDate) {
            this.$Notice.error({ desc: '请选择新的作业日期' })
            return false
          }

          const postData = {
            actionDate: this.newActionDate,
            taskitemIds: JSON.stringify(this.selectedIds),
            workdayId: this.info.id
          }
          modifyTaskitemActionDate(postData).then(res => {
            if (res && res.success) {
              this.$Notice.success({ desc: '操作成功' })
              this.detailModal = false
              this.selectedIds = []
              this.getTableData()
              // 调用父级重新加载右侧数据方法
              this.onSuccess()
            }
          })
        }
      })
    },
    onRmove () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要移除所选项？',
        onOk: async () => {
          const postData = {
            taskitemIds: JSON.stringify(this.selectedIds)
          }
          const result = await removeTaskitem(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.detailModal = false
            this.selectedIds = []
            this.getTableData()
            // 调用父级重新加载右侧数据方法
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
