<template>
  <div>
    <Modal v-model="addStaModal" width="600" footer-hide>
      <p slot="header" class="text-center">
        <span>创建站点-</span>
        <span>{{ taskInfo.workTypeName }}</span><span>({{ taskInfo.startDate + ' - ' + taskInfo.endDate }})</span>
      </p>
      <Form ref="formValidate" :model="stationForm" :rules="fromValid" :label-width="100">
        <FormItem label="项目名称" prop="name">
          <Input size="small" v-model="stationForm.name" placeholder="填写项目名称" />
        </FormItem>
        <FormItem label="作业日期" prop="actionDate">
          <DatePicker type="date" size="small" style="width: 100%;" :clearable="false" placeholder="选择作业日期"
            v-model="stationForm.actionDate"></DatePicker>
        </FormItem>
        <FormItem label="选择线路" prop="assetId">
          <Select size="small" placeholder="选择线路" v-model="stationForm.assetId" transfer @on-change="onChangeAsset">
            <i-option v-for="item in assetArray" :key="'aid_' + item.id" :value="item.id">
              {{ item.name }}
            </i-option>
          </Select>
        </FormItem>
        <FormItem label="选择站点" prop="stationOrTrainId">
          <Select size="small" placeholder="选择站点" v-model="stationForm.stationOrTrainId" transfer>
            <i-option v-for="item in stationArray" :key="'sid_' + item.id" :value="item.id">
              {{ item.name }}
            </i-option>
          </Select>
        </FormItem>
        <FormItem label="工作内容">
          <Input v-model="stationForm.workContent" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="填写工作内容"></Input>
        </FormItem>
        <FormItem label="备注">
          <Input v-model="stationForm.remark" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="填写备注"></Input>
        </FormItem>
        <FormItem class="m-t-20 text-right">
          <Button type="text" class="m-r-5" @click="addStaModal = false">取消</Button>
          <Button type="primary" :loading="btnLoading" @click="onConfirm('formValidate')">确认创建</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'
import { ParseDate } from '@/utils/dateFormat'

import { createWorkday } from '@/api/msp/workdaySchedule'
import { managerCreateWorkday } from '@/api/msp/workdayManage'
export default {
  props: {
    assetArray: {
      type: Array,
      default: new Array(0)
    },
    taskInfo: {
      type: Object
    },
    onSuccess: {
      type: Function,
      default: () => { }
    },
    isManage: {
      type: Boolean,
      default: false
    }
  },
  mixins: [indexMixins],
  data () {
    return {
      addStaModal: false,
      stationArray: [],
      stationForm: {
        name: '',
        actionDate: '',
        stationOrTrainId: null,
        stationOrTrainName: null,
        tableId: null,
        manageId: null,
        assetId: null,
        remark: '',
        workContent: ''
      },
      btnLoading: false,
      fromValid: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        actionDate: [
          { required: true, type: 'date', message: ' ', trigger: 'change' }
        ],
        assetId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        stationOrTrainId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    showModal () {
      this.addStaModal = true
      if (this.isManage) {
        this.stationForm.manageId = this.taskInfo.id
      } else {
        this.stationForm.tableId = this.taskInfo.id
      }
    },
    async onChangeAsset () {
      this.stationForm.stationOrTrainId = null
      this.stationArray = await this.getStationData(this.stationForm.assetId)
    },
    onConfirm (name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          this.stationForm.actionDate = ParseDate(this.stationForm.actionDate)
          this.stationForm.stationOrTrainName = this.stationArray.find(x => x.id === this.stationForm.stationOrTrainId).name
          this.btnLoading = true
          let result = null
          if (this.isManage) {
            result = await managerCreateWorkday(this.stationForm).finally(() => { this.btnLoading = false })
          } else {
            result = await createWorkday(this.stationForm).finally(() => { this.btnLoading = false })
          }
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.addStaModal = false
            this.onSuccess()
          }
          // this.$Modal.confirm({
          //   title: '操作提示',
          //   content: '确定信息无误，并提交？',
          //   onOk: async () => {
          //     this.btnLoading = true
          //     let result = null
          //     if (this.isManage) {
          //       result = await managerCreateWorkday(this.stationForm).finally(() => { this.btnLoading = false })
          //     } else {
          //       result = await createWorkday(this.stationForm).finally(() => { this.btnLoading = false })
          //     }
          //     if (result && result.success) {
          //       this.$Notice.success({ desc: '操作成功' })
          //       this.addStaModal = false
          //       this.onSuccess()
          //     }
          //   }
          // })
        }
      })
    }
  }
}
</script>
