<template>
  <Modal v-model="infoModal" width="600" footer-hide>
    <p slot="header" class="text-center">基础信息编辑</p>
    <Form ref="modifyInfo" :model="modifyData" :rules="ruleValidate" :label-width="100">
      <Row :gutter="8">
        <i-col span="12">
          <FormItem label="施工时间段" prop="workTime">
            <Input v-model="modifyData.workTime" size="small" placeholder="填写施工时间段"></Input>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="施工人数" prop="workerNum">
            <InputNumber v-model="modifyData.workerNum" :min="1" style="width:100%" size="small" placeholder="填写施工人数">
            </InputNumber>
          </FormItem>
        </i-col>
      </Row>
      <FormItem label="备注">
        <Input v-model="modifyData.remark" size="small" type="text" placeholder="填写备注" />
      </FormItem>
      <FormItem label="工作内容">
        <Input v-model="modifyData.workContent" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="填写工作内容"></Input>
      </FormItem>
      <FormItem class="text-right m-t-20">
        <Button type="text" class="m-r-5" @click="infoModal = false">取消</Button>
        <Button type="primary" :loading="submitLoading" @click="onSubmit">确认编辑</Button>
      </FormItem>
    </Form>
  </Modal>
</template>

<script>
import { updateWorkday } from '@/api/msp/workdaySchedule'
export default {
  props: {
    onSuccess: {
      type: Function,
      default: () => { },
      required: false
    }
  },
  data () {
    return {
      infoModal: false,
      info: {},
      submitLoading: false,
      modifyData: {
        workdayId: null,
        workTime: '',
        workerNum: 1,
        remark: '',
        workContent: ''
      },
      ruleValidate: {
        workTime: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],
        workerNum: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    showModal (data) {
      this.info = data
      this.modifyData.workTime = this.info.workTime
      this.modifyData.workdayId = this.info.id
      this.modifyData.workerNum = this.info.workerNumber
      this.modifyData.remark = this.info.remark
      this.modifyData.workContent = this.info.workContent

      this.infoModal = true
    },
    onSubmit () {
      this.$refs.modifyInfo.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误，并提交？',
            onOk: async () => {
              this.submitLoading = true
              const result = await updateWorkday(this.modifyData).finally(() => { this.submitLoading = false })
              if (result && result.success) {
                this.$Notice.success({ desc: '操作成功' })
                this.infoModal = false
                this.onSuccess()
              }
            }
          })
        }
      })
    }
  }
}
</script>
